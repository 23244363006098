import React from 'react';

import { withPrefix } from 'gatsby'

import Meta from '../components/meta';

import { Link } from 'gatsby';

import Contents from '../components/contents';

const Public = () =>{



    return(
        <>

            <Meta
                title="電子公告・決算広告"
                description="アクロスソリューションズの電子公告・決算公告について"
            />

            <Contents>

                <article className="p-recruit-jobs is-policy">

                    <section className="p-recruit-jobs-section">

                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                                電子公告・決算公告
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                {/* <div className="p-recruit-data-box">

                                    <p className="p-recruit-data-box--title">
                                    電子公告
                                    </p>

                                    <div className="p-recruit-data-box-cnt">

                                        <div className="p-recruit-data-box-list">
                                            <ul className="p-recruit-data-box-lists is-none">

                                                <li>
                                                <a href={withPrefix('/public_notice/20220527_acrosssolutions_gappei-koukoku.pdf')} className="c-text--link is-black is-large" target="_blank">
                                                <i class="fa fa-angle-right mr"></i>2022年05月27日　合併公告 <i className="fa fa-file-pdf-o ml"></i>
                                                </a>
                                                </li>

                                                <li>
                                                    <a href={withPrefix('/public_notice/bunkatsu_231120.pdf')} className="c-text--link is-black is-large" target="_blank">
                                                        <i class="fa fa-angle-right mr"></i>吸収分割公告(2023年11月20日)<i className="fa fa-file-pdf-o ml is-pdf"></i>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </div> */}

                                <div className="p-recruit-data-box">

                                    <p className="p-recruit-data-box--title">
                                    決算公告
                                    </p>

                                    <div className="p-recruit-data-box-cnt">

                                        <div className="p-recruit-data-box-list">
                                            <ul className="p-recruit-data-box-lists is-none">

                                                <li>
                                                    <a href={withPrefix('/public_notice/koukoku_kessan_18.pdf')} className="c-text--link is-black is-large" target="_blank">
                                                        <i class="fa fa-angle-right mr"></i>第18期 決算公告 (2023年6月期)<i className="fa fa-file-pdf-o ml is-pdf"></i>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href={withPrefix('/public_notice/koukoku_kessan_17.pdf')} className="c-text--link is-black is-large" target="_blank">
                                                        <i class="fa fa-angle-right mr"></i>第17期 決算公告 (2022年6月期)<i className="fa fa-file-pdf-o ml is-pdf"></i>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </section>

                </article>

            </Contents>

        </>
    )
}

export default Public;


